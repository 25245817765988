import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import {
  AccessTime,
  Block,
  Close,
  Done,
  DoneAll,
  ExpandMore,
  Reply
} from "@material-ui/icons";
import ForwardIcon from "@material-ui/icons/Forward";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Lock from "@material-ui/icons/Lock";
import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";
import ReactHtmlParser from "react-html-parser";

import { AuthContext } from "../../context/Auth/AuthContext";
import { ThemeContext } from "../../context/Theme/ThemeContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import Audio from "../Audio";
import EmailMessageCard from "../EmailMessageCard";
import { ForwardMessagesModal } from "../ForwardMessagesModal";
import LocationPreview from "../LocationPreview";
import MarkdownWrapper from "../MarkdownWrapper";
import MessageOptionsMenu from "../MessageOptionsMenu";
import ModalImageCors from "../ModalImageCors";
import TemplateOnlyBar from "../TemplateOnlyBar";
import VcardPreview from "../VcardPreview";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  ticketNumber: {
    padding: 8,
    fontSize: "18px",
    fontWeight: "bold",
  },

  messagesList: {
    backgroundImage: theme.backgroundImage,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
    gap: "25px",
  },

  circleLoading: {
    color: blue[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageLeft.backgroundColor,
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageLeftDark: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageLeft.backgroundColor,
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  sticker: {
    marginBottom: -25,
    marginLeft: -14,
  },

  stickerMessageLeftDark: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 200,
    display: "block",
    position: "relative",
    height: 18,
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    minHeight: 210,
  },

  stickerMessageLeft: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 200,
    display: "block",
    position: "relative",
    height: 18,
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    minHeight: 210,
  },

  stickerTimestamp: {
    padding: "2px 5px",
    borderRadius: 6,
    backgroundColor: theme.messagesList.messageLeft.backgroundColor,
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageRight.backgroundColor,
    color: theme.messagesList.messageRight.color,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightDark: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageRight.backgroundColor,
    color: theme.messagesList.messageRight.color,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f5f6f6",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerLeftDarkMode: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#1d282f",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#d1f4cc",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerRightDarkMode: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#025144",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  annotation: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginTop: "3px",
    backgroundColor: "#fcecca",
    borderRadius: "5px 3px 0 5px",
    border: "1px solid #f9c66a",
    padding: "3px 10px",

    "& p": {
      padding: "0",
      margin: "0",
    },

    "& span": {
      marginLeft: "auto",
      fontSize: "12px",
      color: "#f9c66a",
    },
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  emailContentItem: {
    display: "flex",
    flexDirection: "column",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: "100%",
    // height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#F5F6F6",
    backgroundColor: "rgba(0,0,0, 0.03)",
    borderRadius: "5px",
    padding: 10,
    gap: 5,
  },

  downloadButton: {
    transform: "rotate(90deg)",
    border: "1px solid #79909B",
    padding: 6,
    marginLeft: 10,
  },

  downloadFileNameFull: {
    width: "100%",
    fontSize: 15,
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },

  downloadFileName: {
    fontSize: 15,
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },

  downloadExtraInfo: {
    fontSize: 11,
    color: "#79909B",
    opacity: "0.7",
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 270,
    color: "#272727",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  emojiBox: {
    backgroundColor: "#fafafa",
    position: "absolute",
    right: 10,
    marginTop: 3,
    borderRadius: "50%",
    padding: "2px 4px",
    boxShadow: "0 1px 3px #b3b3b3",
    zIndex: 1,
  },

  darkEmojiBox: {
    backgroundColor: "#202c33",
    position: "absolute",
    right: 10,
    marginTop: 3,
    borderRadius: "50%",
    padding: "2px 4px",
    boxShadow: "0 1px 3px #202c33",
    zIndex: 1,
  },

  forwardMenu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    backgroundColor: "#F0F2F5",
    position: "absolute",
    bottom: 0,
    marginTop: "30px",
    width: "100%",
    zIndex: 2,

    // svg icons color
    "& svg": {
      color: "#54656F",
    },
  },
}));

const scroll = () => {
  setTimeout(() => {
    const section = document.querySelector("#searchedClicked");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, 300);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function MessagesList({
  contact,
  ticketId,
  isGroup,
  ticketChannel,
  templateBarStatus,
  setTemplateBarStatus,
  aroundMessages,
  setAroundMessages,
  whatsappId,
  // ref,
}) {
  const classes = useStyles();

  const { darkMode } = useContext(ThemeContext);

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonScrollDown, setButtonScrollDown] = useState(false);
  const lastMessageRef = useRef();
  const { user } = useContext(AuthContext);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    // add around messages on top reducer
    if (aroundMessages.messages?.length > 0) {
      dispatch({ type: "RESET" });
      dispatch({ type: "LOAD_MESSAGES", payload: aroundMessages.messages });
    }
  }, [aroundMessages]);

  useEffect(() => {
    if (!forwardModalOpen) {
      setSelectedMessages([]);
    }
  }, [forwardModalOpen]);

  useEffect(() => {
    setLoading(true);

    // const delayDebounceFn = setTimeout(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await api.get(`/messages/${ticketId}`, {
          params: { pageNumber },
        });

        if (currentTicketId.current === ticketId) {
          dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
          setHasMore(data.hasMore);
          setLoading(false);
        }

        if (pageNumber === 1 && data.messages.length > 1) {
          scrollToBottom();
        }
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    };
    fetchMessages();
    // }, 500);

    // return () => {
    // clearTimeout(delayDebounceFn);
    // };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket({
      scope: "messageslist",
      userId: user.id,
      component: "MessagesList",
    });

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }
      // WORKING HERE

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const getAroundMessages = async (createdAt, clickedMessageId) => {
    try {
      const {
        data: {
          messages: { messages },
        },
      } = await api.get(`messages/around/${contact.id}/${createdAt}`);
      setAroundMessages({ messages, clickedMessageId });
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = () => {
    console.log("loadMore");
    console.log({ pageNumber });
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
      setButtonScrollDown(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;

    let scrollBottom =
      e.currentTarget.scrollHeight -
      e.currentTarget.scrollTop -
      e.currentTarget.clientHeight;

    if (scrollBottom !== 0 && scrollBottom > 2) {
      setButtonScrollDown(true);
    } else {
      setButtonScrollDown(false);
    }

    if (!hasMore) return;

    if (loading) {
      return;
    }

    if (scrollTop < 20) {
      const delayDebounceFn = setTimeout(() => {
        loadMore();
      }, 500);

      document.getElementById("messagesList").scrollTop = 200;

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }

    if (scrollTop <= 20 && hasMore) {
      document.getElementById("messagesList").scrollTop = 200;
    }
  };

  useEffect(() => {
    const shouldTemplateOnlyBarRender = () => {
      const latestMessage = messagesList.reduce((acc, cur) => {
        // if the desired behaviour does not include checking if a template has been sent recently, then remove  "|| cur.body.includes("*TEMPLATE*")" check here inside the (!cur.fromMe )
        if (
          cur.createdAt > acc.createdAt &&
          (!cur.fromMe || cur.body.includes("*TEMPLATE*"))
        ) {
          acc = cur;
        }
        return acc;
      });
      const ONE_DAY_IN_MILISECONDS = 24 * 60 * 60 * 1000;
      const today = new Date();
      const messageDate = new Date(latestMessage.createdAt);
      const diffDays = Math.abs((today - messageDate) / ONE_DAY_IN_MILISECONDS);
      // diffdays > 1 means that the message was sent over a day ago
      if (diffDays >= 1) {
        return true;
      }
      return false;
    };

    if (messagesList.length !== 0 && ticketChannel === "whatsappbusiness") {
      setTemplateBarStatus(shouldTemplateOnlyBarRender());
    } else if (ticketChannel !== "whatsappbusiness") {
      setTemplateBarStatus(false);
    } else {
      setTemplateBarStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesList]);

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (
      message.mediaType === "location" &&
      message.metadata
      // && message.body.split("|").length >= 2
    ) {
      const metadata = JSON.parse(JSON.parse(message.metadata));

      if (!metadata.location) return null;

      const {
        location: { latitude, longitude, description },
        body: imageLocation,
      } = metadata;

      const { clientUrl } = metadata._data;

      const linkLocation = `https://www.google.com/maps?q=${latitude},${longitude}&z=17&hl=pt-BR`;

      return (
        <LocationPreview
          image={`data:image/jpeg;base64,${imageLocation}`}
          link={linkLocation}
          description={description}
          clientUrl={clientUrl}
        />
      );
    }

    if (message.mediaType === "vcard") {
      const array = message.body.split("\n");
      const obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        const values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return (
        <VcardPreview
          contact={contact}
          numbers={obj[0]?.number}
          mediaType={message.mediaType}
        />
      );
    }
    if (message.mediaType === "multi_vcard") {
      const array = message.body
        .replace("[{", "")
        .replace("}]", "")
        .split("},{");

      // array format: ['"id":66,"name":"Nathalia","number":"5527988882406"', '"id":12,"name":"Mãe Trabalho","number":"5527999996918"', '"id":72,"name":"Mariah Irmã","number":"5527997420098"']

      const contactsArray = array.map((item) => {
        const obj = {};
        item.split(",").forEach((i) => {
          const [key, value] = i.split(":");
          obj[key.replace('"', "").replace('"', "")] = value
            ?.replace('"', "")
            .replace('"', "");
        });
        return obj;
      });

      return (
        <>
          {/*  {contactsArray.map((contact) => {
            const { name, number } = contact;
            // format number from 5527999999999 to +55 27 99999-9999
            if (!number) return;
            const formattedNumber = `+${number.slice(0, 2)} ${number.slice(
              2,
              4
            )} ${number.slice(4, 9)}-${number.slice(9, 13)}`;

            return (
              <VcardPreview
                contact={name}
                numbers={formattedNumber}
                mediaType={message.mediaType}
              />
            );
          })} */}
          <VcardPreview
            contacts={contactsArray}
            mediaType={message.mediaType}
          />
        </>
      );
    }

    if (message.mediaType === "image") {
      return (
        <>
          <ModalImageCors imageUrl={message.mediaUrl} widthProp={400} />
          <MarkdownWrapper>{message.body}</MarkdownWrapper>
        </>
      );
    }
    if (message.mediaType === "audio") {
      return <Audio url={message.mediaUrl} />;
    }
    if (message.mediaType === "video") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingBottom: "5px",
          }}
        >
          <video
            className={classes.messageMedia}
            src={message.mediaUrl}
            controls
          />
          {message.body && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
        </Box>
      );
    }

    if (message.mediaType !== "email") {
      return (
        <>
          <div className={classes.downloadMedia}>
            <InsertDriveFileIcon
              style={{ color: "#79909B", fontSize: "2.2rem" }}
            />
            <Box>
              <Tooltip title={message.body}>
                <Typography className={classes.downloadFileName}>
                  {message.fromMe && message.body.includes("_")
                    ? message.body.split("_").slice(1).join("_")
                    : message.body}
                </Typography>
              </Tooltip>

              <Box className={classes.downloadExtraInfo}>
                {/* file extension and file size */}
                {`${getFileExtension(message.body)} • ${getFileSize(
                  message.mediaUrl
                )}`}
              </Box>
            </Box>

            <IconButton
              className={classes.downloadButton}
              target="_blank"
              href={message.mediaUrl}
            >
              <ForwardIcon
                style={{
                  color: "#79909B",
                  fontSize: "1rem",
                  transform: "scaleX(1.2)",
                }}
              />
            </IconButton>
          </div>
          <Tooltip title={message.body}>
            <Typography
              className={classes.downloadFileNameFull}
              style={{ marginLeft: "0.5px" }}
            >
              {message.fromMe && message.body.includes("_")
                ? message.body.split("_").slice(1).join("_")
                : message.body}
            </Typography>
          </Tooltip>
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      const messageDay = parseISO(messagesList[index].createdAt);
      const previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      const messageTicket = message.ticketId;
      const previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} className={classes.ticketNumber}>
            Ticket #{messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      const messageUser = messagesList[index].fromMe;
      const previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return <span style={{ marginTop: 16 }} key={`divider-${message.id}`} />;
      }
    }
  };

  const renderQuotedMessage = (message) => (
    <div
      onClick={() => {
        getAroundMessages(message.quotedMsg.createdAt, message.quotedMsg.id);
      }}
      style={{
        cursor: "pointer",
      }}
      className={
        darkMode
          ? clsx(classes.quotedContainerLeftDarkMode, {
              [classes.quotedContainerRightDarkMode]: message.fromMe,
            })
          : clsx(classes.quotedContainerLeft, {
              [classes.quotedContainerRight]: message.fromMe,
            })
      }
    >
      <span
        className={clsx(classes.quotedSideColorLeft, {
          [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
        })}
      />
      <div className={classes.quotedMsg}>
        {!message.quotedMsg?.fromMe && (
          <span className={classes.messageContactName}>
            {message.quotedMsg?.contact?.name}
          </span>
        )}
        {message.quotedMsg?.body}
      </div>
    </div>
  );

  function sortByKey(array, key) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  const handleSelectedMessages = (messageId) => {
    if (selectedMessages.includes(messageId)) {
      setSelectedMessages(
        selectedMessages.filter((message) => message !== messageId)
      );
    } else {
      setSelectedMessages([...selectedMessages, messageId]);
    }
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = sortByKey(messagesList, "createdAt").map(
        (message, index) => {
          if (message.mediaType === "annotation") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <div className={classes.annotation}>
                  {ReactHtmlParser(message.body)}
                  <span
                    outLined
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3em",
                    }}
                  >
                    <Lock fontSize="inherit" />
                    {message.emoji && (
                      <Box
                        className={
                          !darkMode ? classes.emojiBox : classes.darkEmojiBox
                        }
                      >
                        {message.emoji}
                      </Box>
                    )}

                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </React.Fragment>
            );
          }
          if (message.mediaType === "call_log") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <div
                  className={classes.messageCenter}
                  id={
                    message.id === aroundMessages.clickedMessageId
                      ? "searchedClicked"
                      : ""
                  }
                  onClick={
                    message.id === aroundMessages.clickedMessageId && scroll()
                  }
                  // ref={
                  //   message.id === aroundMessages.clickedMessageId
                  //     ? executeScroll()
                  //     : null
                  // }
                >
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                      width="20"
                      height="17"
                    >
                      <path
                        fill="#df3333"
                        d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                      />
                    </svg>{" "}
                    <span>
                      Chamada de voz/vídeo perdida às{" "}
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            );
          }

          if (!message.fromMe) {
            return (
              <>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderNumberTicket(message, index)}
                <Box key={message.id} display="flex" alignItems="center">
                  {selectedMessages.length > 0 ? (
                    <Checkbox
                      onClick={() => handleSelectedMessages(message.id)}
                      checked={selectedMessages.includes(message.id)}
                    />
                  ) : (
                    <span></span>
                  )}
                  {message.mediaType === "sticker" ? (
                    <div
                      className={[
                        darkMode
                          ? classes.stickerMessageLeftDark
                          : classes.stickerMessageLeft,
                      ]}
                    >
                      <Box className={classes.sticker}>
                        <ModalImageCors
                          imageUrl={message.mediaUrl}
                          widthProp={"190px"}
                          heightProp={"190px"}
                        />
                      </Box>
                      <span className={classes.stickerTimestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={[
                        darkMode
                          ? classes.messageLeftDark
                          : classes.messageLeft,
                      ]}
                      style={
                        message.mediaType === "email"
                          ? {
                              padding: "0.5em 0 0 ",
                              margin: "0.5em 0 0",
                            }
                          : null
                      }
                      id={
                        message.id === aroundMessages.clickedMessageId
                          ? "searchedClicked"
                          : ""
                      }
                      onClick={
                        message.id === aroundMessages.clickedMessageId &&
                        scroll()
                      }
                      // ref={
                      //   message.id === aroundMessages.clickedMessageId
                      //     ? executeScroll()
                      //     : null
                      // }
                    >
                      {message.mediaType !== "email" && (
                        <IconButton
                          variant="contained"
                          size="small"
                          id="messageActionsButton"
                          disabled={message.isDeleted}
                          className={classes.messageActionsButton}
                          onClick={(e) =>
                            handleOpenMessageOptionsMenu(e, message)
                          }
                        >
                          <ExpandMore />
                        </IconButton>
                      )}
                      {isGroup && (
                        <span className={classes.messageContactName}>
                          {message.contact?.name}
                        </span>
                      )}
                      {(message.mediaUrl ||
                        message.mediaType === "location" ||
                        message.mediaType === "vcard" ||
                        message.mediaType === "multi_vcard") &&
                        message.mediaType !== "sticker" &&
                        checkMessageMedia(message)}
                      <Box
                        className={
                          message.mediaType !== "email"
                            ? classes.textContentItem
                            : undefined
                        }
                      >
                        {message.quotedMsg && renderQuotedMessage(message)}

                        {message && message.mediaType === "email" ? (
                          <EmailMessageCard message={message} />
                        ) : (
                          message &&
                          message.mediaType === "chat" && (
                            <MarkdownWrapper>{message.body}</MarkdownWrapper>
                          )
                        )}

                        {message.emoji && (
                          <Box
                            className={
                              !darkMode
                                ? classes.emojiBox
                                : classes.darkEmojiBox
                            }
                          >
                            {message.emoji}
                          </Box>
                        )}

                        <span className={classes.timestamp}>
                          {format(parseISO(message.createdAt), "HH:mm")}
                        </span>
                      </Box>
                    </div>
                  )}
                </Box>
              </>
            );
          }

          return (
            <>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}

              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                {selectedMessages.length > 0 ? (
                  <Checkbox
                    onClick={() => handleSelectedMessages(message.id)}
                    checked={selectedMessages.includes(message.id)}
                  />
                ) : (
                  <span></span>
                )}

                <div
                  className={[
                    darkMode ? classes.messageRightDark : classes.messageRight,
                  ]}
                  style={
                    message.mediaType === "email"
                      ? {
                          padding: 0,
                          margin: "0.5em 0 0",
                        }
                      : null
                  }
                  id={
                    message.id === aroundMessages.clickedMessageId
                      ? "searchedClicked"
                      : ""
                  }
                  onClick={
                    message.id === aroundMessages.clickedMessageId && scroll()
                  }
                >
                  {message.mediaType !== "email" && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  {(message.mediaUrl ||
                    message.mediaType === "location" ||
                    message.mediaType === "vcard" ||
                    message.mediaType === "multi_vcard") &&
                    //
                    checkMessageMedia(message)}
                  {message.mediaType !== "email" ? (
                    <div
                      className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: message.isDeleted,
                      })}
                    >
                      {message.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}
                      {message.quotedMsg && renderQuotedMessage(message)}

                      {
                        (message.mediaType === "chat" ||
                        message.mediaType === 'template') && (
                          <MarkdownWrapper>{message.body}</MarkdownWrapper>
                        )
                      }

                      {message.emoji && (
                        <Box
                          className={
                            !darkMode ? classes.emojiBox : classes.darkEmojiBox
                          }
                        >
                          {message.emoji}
                        </Box>
                      )}

                      <span className={classes.timestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  ) : (
                    <Box
                      className={
                        message.mediaType !== "email" && classes.textContentItem
                      }
                    >
                      {message.quotedMsg && renderQuotedMessage(message)}
                      {message && message.mediaType === "email" ? (
                        <EmailMessageCard message={message} />
                      ) : (
                        <MarkdownWrapper>{message.body}</MarkdownWrapper>
                      )}
                      {message.emoji && (
                        <Box
                          className={
                            !darkMode ? classes.emojiBox : classes.darkEmojiBox
                          }
                        >
                          {message.emoji}
                        </Box>
                      )}

                      <span className={classes.timestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </Box>
                  )}
                </div>
              </Box>
            </>
          );
        }
      );
      return viewMessagesList;
    }
    return <div>Say hello to your new contact!</div>;
  };

  const getFileExtension = (filename) => {
    // slice filename from the last dot
    const extension = filename.slice(
      ((filename.lastIndexOf(".") - 1) >>> 0) + 2
    );

    return extension;
  };

  const getFileSize = (mediaUrl) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", mediaUrl, false);
    xhr.send();

    const fileSize = xhr.getResponseHeader("content-length");

    if (fileSize >= 1048576) {
      return `${(fileSize / 1048576).toFixed(2)} MB`;
    }
    if (fileSize >= 1024) {
      return `${(fileSize / 1024).toFixed(2)} KB`;
    }
    if (fileSize > 1) {
      return `${fileSize} B`;
    }
    return `${fileSize} B`;
  };

  return (
    <div className={classes.messagesListWrapper}>
      {ticketChannel === "whatsappbusiness" && templateBarStatus && (
        <TemplateOnlyBar />
      )}
      {(ticketChannel === "whatsappbusiness" || ticketChannel === "whatsapp") &&
        selectedMessages.length > 0 && (
          <Box className={classes.forwardMenu}>
            <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
              <IconButton onClick={() => setSelectedMessages([])}>
                <Close />
              </IconButton>
              <Typography>
                {`${selectedMessages.length} ${
                  selectedMessages.length === 1 ? "selecionada" : "selecionadas"
                }`}
              </Typography>
            </Box>
            <Box display="flex" style={{ gap: "1rem" }}>
              {/* <IconButton>
              <Delete />
            </IconButton> */}
              <IconButton onClick={() => setForwardModalOpen(true)}>
                <Reply
                  style={{
                    // invert x axis
                    transform: "scaleX(-1)",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        )}

      <MessageOptionsMenu
        message={selectedMessage}
        selectedMessages={selectedMessages}
        setSelectedMessages={setSelectedMessages}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />

      <ForwardMessagesModal
        modalOpen={forwardModalOpen}
        onClose={() => setForwardModalOpen(false)}
        selectedMessages={selectedMessages}
        whatsappId={whatsappId}
      />

      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
        style={
          selectedMessages.length > 0 ? { paddingBottom: "3.5rem" } : undefined
        }
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>

      {buttonScrollDown && (
        <IconButton
          style={
            // {
            //   position: "fixed",
            //   bottom: 120,
            //   // right: 10,
            //   backgroundColor: "#202c33",
            //   dropShadow: "0 1px 1px #b3b3b3",
            //   color: "#798287",
            // }
            darkMode
              ? {
                  position: "fixed",
                  bottom: 120,
                  right: 10,
                  backgroundColor: "#202c33",
                  dropShadow: "0 1px 1px #b3b3b3",
                  color: "#798287",
                }
              : {
                  position: "fixed",
                  bottom: 120,
                  right: 10,
                  backgroundColor: "#fff",
                  dropShadow: "0 1px 1px #b3b3b3",
                }
          }
          onClick={async () => {
            setPageNumber(1);
            setAroundMessages([]);
            try {
              const { data } = await api.get(`/messages/${ticketId}`, {
                params: { pageNumber },
              });

              if (currentTicketId.current === ticketId) {
                dispatch({ type: "RESET" });
                dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                setHasMore(data.hasMore);
                setLoading(false);
              }

              if (pageNumber === 1 && data.messages.length > 1) {
                scrollToBottom();
              }
            } catch (err) {
              setLoading(false);
              toastError(err);
            }

            scrollToBottom();
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
}

export default MessagesList;
