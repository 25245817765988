import React, { useEffect, useState } from "react";

import {
  Divider,
  makeStyles,
  Box,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";

import {
  parseISO,
  format,
  isWithinInterval,
  subDays,
  isYesterday,
  isToday,
} from "date-fns";
import { blue } from "@material-ui/core/colors";

import {
  AccessTime,
  CloseOutlined,
  Done,
  DoneAll,
  Lock,
  SearchOutlined,
} from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import { ptBR, enUS, es } from "date-fns/locale";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    // padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 160,
    height: 160,
    borderRadius: 10,
  },

  contactHeader: {
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },

  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  ticketNumber: {
    padding: 8,
    fontSize: "18px",
    fontWeight: "bold",
  },

  messagesList: {
    // backgroundImage: theme.backgroundImage,
    backgroundColor: "#f5f5f5",
    // margin: 9,
    border: "1px solid #e0e0e0",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 10px 20px 10px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: blue[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 250,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageLeft.backgroundColor,
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageLeftDark: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 250,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageLeft.backgroundColor,
    color: theme.messagesList.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 250,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    // overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 250,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageRight.backgroundColor,
    color: theme.messagesList.messageRight.color,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightDark: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 250,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.messagesList.messageRight.backgroundColor,
    color: theme.messagesList.messageRight.color,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  normalMessage: {
    // whatsapp search message styles
    // maxWidth: "90%",
    width: "100%",
    backgroundColor: "#f5f5f5",
    padding: "15px 5px 10px 5px",
    color: "#6f7d81",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    // set hover effect
    "&:hover": {
      backgroundColor: "#e5e5e5",
    },
  },

  timestamp: {
    padding: "3px 0px 0px 5px",
    marginBottom: "-10px",
    fontSize: 10,
    position: "relative",
    // marginBottom: "35px",
    // marginRight: "-40px",
    // right: 5,
    color: "#6f7d81",
  },

  normalBody: {
    maxWidth: "90%",
    // width: "50px",
    // textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    // overflow: "hidden",
    backgroundColor: "#f5f6f6",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerLeftDarkMode: {
    margin: "-3px -80px 6px -6px",
    // overflow: "hidden",
    backgroundColor: "#1d282f",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#d1f4cc",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedContainerRightDarkMode: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#025144",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 250,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  annotation: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginTop: "3px",
    backgroundColor: "#fcecca",
    borderRadius: "5px 3px 0 5px",
    border: "1px solid #f9c66a",
    padding: "3px 10px",

    "& p": {
      padding: "0",
      margin: "0",
    },

    "& span": {
      marginLeft: "auto",
      fontSize: "12px",
      color: "#f9c66a",
    },
  },

  textContentItem: {
    // overflowWrap: "break-word",
    // width: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    // padding: "3px 80px 6px 6px",
  },

  emailContentItem: {
    display: "flex",
    flexDirection: "column",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    // marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 250,
    color: "#272727",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },
}));

function SearchDrawer({
  open,
  handleDrawerClose,
  contact,
  ticket,
  setAroundMessages,
}) {
  const classes = useStyles();

  // const [modalOpen, setModalOpen] = useState(false);
  const [searchedMessages, setSearchedMessages] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const searchMessages = async (pageNum) => {
    const { data } = await api.get(`/messages/search/${contact.id}`, {
      params: { searchParam, pageNumber: pageNum },
    });
    if (pageNum === 1) {
      setSearchedMessages(data.messages);
    } else {
      setSearchedMessages([...searchedMessages, ...data.messages]);
    }
    setHasMore(data.hasMore);
  };

  useEffect(() => {
    setSearchParam("");
    setSearchedMessages([]);
  }, [open, ticket]);

  const getAroundMessages = async (createdAt, clickedMessageId) => {
    try {
      const {
        data: {
          messages: { messages },
        },
      } = await api.get(`messages/around/${ticket.contact.id}/${createdAt}`);
      setAroundMessages({ messages, clickedMessageId });
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = () => {
    const pageNum = pageNumber + 1;
    searchMessages(pageNum);
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (scrollTop === scrollHeight - clientHeight) {
      loadMore();
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const highlightBody = (messageBody) => {
    const htmlStringfied = messageBody
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replace(
        searchParam,
        `<strong style="color:#04db81; front-weight: 900">${searchParam}</strong>`
      );

    return ReactHtmlParser(
      `<p style="width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">${htmlStringfied}</p>`
    );
  };

  const renderDate = (message) => {
    // d.setDate(d.getDate()-5)

    if (message.mediaType === "annotation") {
      return (
        <span
          outLined
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.3em",
          }}
        >
          {/* <Lock fontSize="inherit" /> */}
          {format(parseISO(message.createdAt), "dd/MM/yy - HH:mm")}
        </span>
      );
    }
    if (isToday(parseISO(message.createdAt))) {
      return format(parseISO(message.createdAt), "HH:mm");
    }
    if (isYesterday(parseISO(message.createdAt))) {
      const yesterday = i18n.t("searchDrawer.yesterday");

      return yesterday;
    }
    if (
      isWithinInterval(parseISO(message.createdAt), {
        start: subDays(new Date(), 7),
        end: new Date(),
      })
    ) {
      if (i18n.language === "en") {
        return format(parseISO(message.createdAt), "EEEE", {
          locale: enUS,
        });
      }
      if (i18n.language === "es") {
        return format(parseISO(message.createdAt), "EEEE", {
          locale: es,
        });
      }
      if (i18n.language === "pt-BR") {
        return format(parseISO(message.createdAt), "EEEE", {
          locale: ptBR,
        });
      }
    } else {
      return format(parseISO(message.createdAt), "dd/MM/yy");
    }
  };

  const renderMessages = () => {
    if (searchedMessages.length > 0) {
      const viewMessagesList = searchedMessages.map((message, index) => {
        if (message.mediaType === "annotation") {
          return (
            <React.Fragment key={message.id}>
              <div
                className={classes.annotation}
                onClick={() => getAroundMessages(message.createdAt, message.id)}
              >
                {highlightBody(message.body)}
                <span
                  outLined
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.3em",
                  }}
                >
                  <Lock fontSize="inherit" />
                  {/* {format(parseISO(message.createdAt), "dd/MM/yy - HH:mm")} */}
                  {renderDate(message)}
                </span>
              </div>
              <Divider />
            </React.Fragment>
          );
        }
        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              <div
                className={[classes.normalMessage]}
                onClick={() => getAroundMessages(message.createdAt, message.id)}
              >
                <span className={classes.timestamp}>{renderDate(message)}</span>
                {/* <span className={classes.messageContactName}>
                  {message.contact?.name}
                </span> */}

                <Box
                  className={
                    message.mediaType !== "email"
                      ? classes.textContentItem
                      : undefined
                  }
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  {highlightBody(message.body)}
                </Box>
              </div>
              <Divider />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={message.id}>
            <div
              className={[classes.normalMessage]}
              onClick={() => getAroundMessages(message.createdAt, message.id)}
            >
              <span className={classes.timestamp}>{renderDate(message)}</span>

              <Box
                className={
                  message.mediaType !== "email" && classes.textContentItem
                }
              >
                {renderMessageAck(message)}
                {highlightBody(message.body)}
              </Box>
            </div>
            <Divider />
          </React.Fragment>
        );
      });
      return viewMessagesList;
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <IconButton color="primary" onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ justifySelf: "center" }}>
          {i18n.t("searchDrawer.header")}
        </Typography>
      </div>
      <TextField
        id="outlined-basic"
        label={i18n.t("searchDrawer.searchLabel")}
        variant="outlined"
        size="small"
        style={{ margin: "8px" }}
        value={searchParam}
        onChange={(e) => {
          setSearchParam(e.target.value);
          setSearchedMessages([]);
        }}
        onKeyPress={(e) => {
          e.key === "Enter" && searchMessages(pageNumber);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                {searchParam.length === 0 ? (
                  <SearchOutlined
                    onClick={() => {
                      searchMessages(pageNumber);
                    }}
                  />
                ) : (
                  <CloseOutlined
                    onClick={() => {
                      setSearchParam("");
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.messagesListWrapper}>
        <div
          id="messagesList"
          className={classes.messagesList}
          onScroll={handleScroll}
        >
          {searchedMessages.length > 0 ? renderMessages() : []}
        </div>
      </div>
    </Drawer>
  );
}

export default SearchDrawer;
